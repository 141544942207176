<template>
  <a-col class="h-100 overflow-hidden org">
    <a-button
      type="primary"
      class="back-btn"
      style="margin-top: 20px"
      @click="$router.push({ name: 'opendata' })"
    >
      <i class="t-transition icons icon-chevron-left" />
      Назад
    </a-button>
    <a-row class="content-title" type="flex">
      <h2 style="margin-bottom: 0">
        {{
          getTitle &&
          getTitle[0] &&
          getTitle[0].title &&
          getTitle[0].title[$i18n.locale]
        }}
      </h2>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="
          $router.push({
            name: 'opendata-list-create'
          })
        "
      >
        <a-icon type="plus" />
        {{ $t("Add") }}
      </a-button>
    </a-row>

    <spinner v-if="loading" />

    <a-row v-else class="list-container menu-list__container thin-scroll">
      <Tree v-if="list.length > 0" :value="sortChildMenuList(list)">
        <a-row
          v-if="!node.isDragPlaceHolder"
          slot-scope="{ node, tree, path }"
          type="flex"
          align="middle"
        >
          <a-icon
            v-if="node.children && node.children.length"
            style="padding: 10px 10px 10px 0"
            :type="node.$folded ? 'plus' : 'minus'"
            @click="tree.toggleFold(node, path)"
          />

          <a-input-number
            v-model="node.position"
            style="width: 60px; margin-right: 10px"
            :min="1"
            @pressEnter="updatePosition(node, $event)"
          />

          <router-link
            :to="{
              name: 'opendata-list-update',
              params: { id: node.id }
            }"
            class="td-post-item"
            tag="span"
          >
            <span v-if="node.is_main" class="mr-1">
              <a-icon type="pushpin" />
            </span>
            <span>(ID: {{ node.id }}) {{ node.title || "Нет название" }}</span>
          </router-link>

          <a-row class="btns" type="flex" align="middle">
            <a-button
              type="primary"
              style="margin-right: 10px"
              class="action-btns"
              @click="
                $router.push({
                  name: 'opendata-docs',
                  params: { list_id: node.id }
                })
              "
            >
              Документы
            </a-button>

            <div class="d-flex__center-items__col-reverse">
              <a-switch
                v-model="node.is_active"
                style="margin-top: 5px"
                @change="checkActive(node, $event)"
              />
              <a-tag
                style="min-width: 110px; text-align: center"
                :color="node.is_active ? 'geekblue' : 'volcano'"
              >
                {{ node.is_active ? $t("Published") : $t("NotPublished") }}
              </a-tag>
            </div>

            <a-button
              type="primary"
              class="edit-btn"
              @click="
                $router.push({
                  name: 'opendata-list-update',
                  params: { id: node.id }
                })
              "
            >
              <!--{{ $t("Edit") }}-->
              <a-icon type="edit" class="action-btns" />
            </a-button>

            <a-popconfirm
              title="Вы действительно хотите удалить?"
              ok-text="Да"
              cancel-text="Нет"
              @confirm="removeItem(node)"
            >
              <a-button style="margin-right: 10px" type="danger">
                <!--{{ $t("Delete") }}-->
                <a-icon class="action-btns" type="delete" />
              </a-button>
            </a-popconfirm>

            <a-row class="btns" type="flex-row" align="middle">
              <div>
                <b>{{ $t("TableCreatedDate") }}: </b>
                <span>{{ moment(node.created_at).format("LLL") }}</span>
              </div>

              <hr />

              <div>
                <b>{{ $t("TableLastUpdatedDate") }}: </b>
                <span>{{ moment(node.updated_at).format("LLL") }}</span>
              </div>
            </a-row>
          </a-row>
        </a-row>
      </Tree>
    </a-row>
  </a-col>
</template>

<script>
import "he-tree-vue/dist/he-tree-vue.css"
import { Tree, Fold } from "he-tree-vue"

export default {
  components: {
    Tree: Tree.mixPlugins([Fold])
    // breadthFirstSearch
  },

  data() {
    return {
      tableProps: [
        /*{
          title: {
            uz: "3299 Nizom bo'yicha ma'lumotlar",
            oz: "3299 Низом бўйича маьлумотлар",
            ru: "3299 Низом бўйича маьлумотлар",
            en: "3299 Низом бўйича маьлумотлар"
          },
          id: 39
        },
        {
          title: {
            uz: "PF-6247 bo'yicha ma'lumotlar",
            oz: "ПФ-6247  бўйича маълумотлар",
            ru: "3299 Низом бўйича маьлумотлар",
            en: "3299 Низом бўйича маьлумотлар"
          },
          id: 40
        }*/
      ],
      loading: false,
      list: [],
      title: {
        oz: " ",
        uz: " ",
        qr: " ",
        ru: " ",
        en: " "
      }
    }
  },
  computed: {
    getTitle() {
      return this.tableProps.filter((el) => {
        return el.id === this.$route.params.open_id
      })
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async checkActive(item, event) {
      await this.$api.patch(
        `/admin/budget/legally-open-information/${item.id}/update/`,
        {
          is_active: event
        }
      )
    },
    async fetchData() {
      this.loading = true
      try {
        const data = await this.$api.get(
          "/admin/budget/legally-open-information/list/",
          {
            params: {
              menu: this.$route.params.open_id,
              parent: true
            }
          }
        )
        this.$set(this, "list", data.data)
        this.loading = false
      } catch (err) {
        console.error(err)
        this.$message.error("Ошибка при загрузке данных")
      }
      this.loading = false
    },
    async removeItem(arg) {
      try {
        const res = await this.$api.delete(
          `/admin/budget/legally-open-information/${arg.id}/delete/`
        )
        if (res) {
          this.$message.success("Успешно удален")
          await this.fetchData()
        } else {
          this.$message.error("Ошибка при удалении")
          throw "Не удалось удалить"
        }
        // console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    updatePosition(node, event) {
      this.loading = true
      this.$api
        .patch(`/admin/budget/legally-open-information/${node.id}/update/`, {
          position: event.target.value
        })
        .then(() => {
          this.loading = false
          this.$message.success("Позиция обновлена")
          this.fetchData()
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err.message)
        })
    },
    sortChildMenuList(list) {
      return (
        list.length > 0 &&
        list
          .map((item) => ({
            ...item,
            $folded: true,
            children:
              (item.children && this.sortChildMenuList(item.children)) || []
          }))
          .sort((a, b) => a.position - b.position)
      )
    }
  }
}
</script>

<style lang="scss">
.tree-node-inner {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
}

// .draggable-placeholder {
// }

.draggable-placeholder-inner {
  border: 1px dashed #0088f8;
  box-sizing: border-box;
  background: rgba(0, 136, 249, 0.09);
  color: #0088f9;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
}

.tree3 .tree-node-inner {
  border: none;
  padding: 0px;
}

.tree3 .tree-node-inner-back:hover {
  background: #ddd;
}

.tree4 .tree-node-inner {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  background: #ccc;
}

.tree4 .draggable-placeholder-inner {
  background-color: orangered;
}
.he-tree .tree-node {
  padding: 8px 15px;
}
.btns hr {
  margin: 3px 0;
}
</style>
